var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
}); // Reds

var R50 = exports.R50 = "#FFEBE6";
var R75 = exports.R75 = "#FFBDAD";
var R100 = exports.R100 = "#FF8F73";
var R200 = exports.R200 = "#FF7452";
var R300 = exports.R300 = "#FF5630";
var R400 = exports.R400 = "#DE350B";
var R500 = exports.R500 = "#BF2600"; // Yellows

var Y50 = exports.Y50 = "#FFFAE6";
var Y75 = exports.Y75 = "#FFF0B3";
var Y100 = exports.Y100 = "#FFE380";
var Y200 = exports.Y200 = "#FFC400";
var Y300 = exports.Y300 = "#FFAB00";
var Y400 = exports.Y400 = "#FF991F";
var Y500 = exports.Y500 = "#FF8B00"; // Greens

var G50 = exports.G50 = "#E3FCEF";
var G75 = exports.G75 = "#ABF5D1";
var G100 = exports.G100 = "#79F2C0";
var G200 = exports.G200 = "#57D9A3";
var G300 = exports.G300 = "#36B37E";
var G400 = exports.G400 = "#00875A";
var G500 = exports.G500 = "#006644"; // Blues

var B50 = exports.B50 = "#DEEBFF";
var B75 = exports.B75 = "#B3D4FF";
var B100 = exports.B100 = "#4C9AFF";
var B200 = exports.B200 = "#2684FF";
var B300 = exports.B300 = "#0065FF";
var B400 = exports.B400 = "#0052CC";
var B500 = exports.B500 = "#0747A6"; // Purples

var P50 = exports.P50 = "#EAE6FF";
var P75 = exports.P75 = "#C0B6F2";
var P100 = exports.P100 = "#998DD9";
var P200 = exports.P200 = "#8777D9";
var P300 = exports.P300 = "#6554C0";
var P400 = exports.P400 = "#5243AA";
var P500 = exports.P500 = "#403294"; // Teals

var T50 = exports.T50 = "#E6FCFF";
var T75 = exports.T75 = "#B3F5FF";
var T100 = exports.T100 = "#79E2F2";
var T200 = exports.T200 = "#00C7E6";
var T300 = exports.T300 = "#00B8D9";
var T400 = exports.T400 = "#00A3BF";
var T500 = exports.T500 = "#008DA6"; // Neutrals

var N0 = exports.N0 = "#FFFFFF";
var N10 = exports.N10 = "#FAFBFC";
var N20 = exports.N20 = "#F4F5F7";
var N30 = exports.N30 = "#EBECF0";
var N40 = exports.N40 = "#DFE1E6";
var N50 = exports.N50 = "#C1C7D0";
var N60 = exports.N60 = "#B3BAC5";
var N70 = exports.N70 = "#A5ADBA";
var N80 = exports.N80 = "#97A0AF";
var N90 = exports.N90 = "#8993A4";
var N100 = exports.N100 = "#7A869A";
var N200 = exports.N200 = "#6B778C";
var N300 = exports.N300 = "#5E6C84";
var N400 = exports.N400 = "#505F79";
var N500 = exports.N500 = "#42526E";
var N600 = exports.N600 = "#344563";
var N700 = exports.N700 = "#253858";
var N800 = exports.N800 = "#172B4D"; // ATTENTION: update the tints if you update this

var N900 = exports.N900 = "#091E42"; // Each tint is made of N900 and an alpha channel

var N10A = exports.N10A = "rgba(9, 30, 66, 0.02)";
var N20A = exports.N20A = "rgba(9, 30, 66, 0.04)";
var N30A = exports.N30A = "rgba(9, 30, 66, 0.08)";
var N40A = exports.N40A = "rgba(9, 30, 66, 0.13)";
var N50A = exports.N50A = "rgba(9, 30, 66, 0.25)";
var N60A = exports.N60A = "rgba(9, 30, 66, 0.31)";
var N70A = exports.N70A = "rgba(9, 30, 66, 0.36)";
var N80A = exports.N80A = "rgba(9, 30, 66, 0.42)";
var N90A = exports.N90A = "rgba(9, 30, 66, 0.48)";
var N100A = exports.N100A = "rgba(9, 30, 66, 0.54)";
var N200A = exports.N200A = "rgba(9, 30, 66, 0.60)";
var N300A = exports.N300A = "rgba(9, 30, 66, 0.66)";
var N400A = exports.N400A = "rgba(9, 30, 66, 0.71)";
var N500A = exports.N500A = "rgba(9, 30, 66, 0.77)";
var N600A = exports.N600A = "rgba(9, 30, 66, 0.82)";
var N700A = exports.N700A = "rgba(9, 30, 66, 0.89)";
var N800A = exports.N800A = "rgba(9, 30, 66, 0.95)";
export default exports;