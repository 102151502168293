import * as _core3 from "@emotion/core";

var _core2 = "default" in _core3 ? _core3.default : _core3;

import * as _react4 from "react";

var _react3 = "default" in _react4 ? _react4.default : _react4;

import * as _reactTransitionGroup3 from "react-transition-group";

var _reactTransitionGroup2 = "default" in _reactTransitionGroup3 ? _reactTransitionGroup3.default : _reactTransitionGroup3;

import _ToastElement2 from "./ToastElement";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ToastContainer = undefined;

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _core = _core2;
var _react = _react3;

var _react2 = _interopRequireDefault(_react);

var _reactTransitionGroup = _reactTransitionGroup2;
var _ToastElement = _ToastElement2;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}
/** @jsx jsx */


var placements = {
  "top-left": {
    top: 0,
    left: 0
  },
  "top-center": {
    top: 0,
    left: "50%",
    transform: "translateX(-50%)"
  },
  "top-right": {
    top: 0,
    right: 0
  },
  "bottom-left": {
    bottom: 0,
    left: 0
  },
  "bottom-center": {
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)"
  },
  "bottom-right": {
    bottom: 0,
    right: 0
  }
};

var ToastContainer = function ToastContainer(_ref) {
  var hasToasts = _ref.hasToasts,
      placement = _ref.placement,
      props = _objectWithoutProperties(_ref, ["hasToasts", "placement"]);

  return (0, _core.jsx)("div", _extends({
    className: "react-toast-notifications__container",
    css: _extends({
      boxSizing: "border-box",
      maxHeight: "100%",
      maxWidth: "100%",
      overflow: "hidden",
      padding: _ToastElement.gutter,
      pointerEvents: hasToasts ? null : "none",
      position: "fixed",
      zIndex: 1000
    }, placements[placement])
  }, props));
};

exports.ToastContainer = ToastContainer;
export default exports;